import { COUNTRY_REQUEST, STATE_REQUEST, CITY_REQUEST, USER_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
    getCountryListLoading: false,
    getCountryListSuccess: false,
    getCountryListFailure: false,
    getCountryListError: null,
    countryList: [],

    getStateListLoading: false,
    getStateListSuccess: false,
    getStateListFailure: false,
    getStateListError: null,
    stateList: [],

    getCityListLoading: false,
    getCityListSuccess: false,
    getCityListFailure: false,
    getCityListError: null,
    cityList: [],

    getUserSelectListLoading: false,
    getUserSelectListSuccess: false,
    getUserSelectListFailure: false,
    getUserSelectListError: null,
    userSelectList: [],
};

export const commonReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case COUNTRY_REQUEST.GET_COUNTRY_LIST_REQUEST:
            return {
                ...state,
                getCountryListLoading: true,
                getCountryListSuccess: false,
                getCountryListFailure: false,
                getCountryListError: null,
            }
        case COUNTRY_REQUEST.GET_COUNTRY_LIST_FAILURE:
            return {
                ...state,
                getCountryListLoading: false,
                getCountryListSuccess: false,
                getCountryListFailure: true,
                getCountryListError: action.payload
            }
        case COUNTRY_REQUEST.GET_COUNTRY_LIST_SUCCESS:
            return {
                ...state,
                getCountryListLoading: false,
                getCountryListSuccess: true,
                getCountryListFailure: false,
                getCountryListError: null,
                countryList: action.payload
            }
        case STATE_REQUEST.GET_STATE_LIST_REQUEST:
            return {
                ...state,
                getStateListLoading: true,
                getStateListSuccess: false,
                getStateListFailure: false,
                getStateListError: null,
            }
        case STATE_REQUEST.GET_STATE_LIST_FAILURE:
            return {
                ...state,
                getStateListLoading: false,
                getStateListSuccess: false,
                getStateListFailure: true,
                getStateListError: action.payload
            }
        case STATE_REQUEST.GET_STATE_LIST_SUCCESS:
            return {
                ...state,
                getStateListLoading: false,
                getStateListSuccess: true,
                getStateListFailure: false,
                getStateListError: null,
                stateList: action.payload
            }
        case CITY_REQUEST.GET_CITY_LIST_REQUEST:
            return {
                ...state,
                getCityListLoading: true,
                getCityListSuccess: false,
                getCityListFailure: false,
                getCityListError: null,
            }
        case CITY_REQUEST.GET_CITY_LIST_FAILURE:
            return {
                ...state,
                getCityListLoading: false,
                getCityListSuccess: false,
                getCityListFailure: true,
                getCityListError: action.payload
            }
        case CITY_REQUEST.GET_CITY_LIST_SUCCESS:
            return {
                ...state,
                getCityListLoading: false,
                getCityListSuccess: true,
                getCityListFailure: false,
                getCityListError: null,
                cityList: action.payload
            }
        case USER_REQUEST.GET_USER_SELECT_LIST_REQUEST:
            return {
                ...state,
                getUserSelectListLoading: true,
                getUserSelectListSuccess: false,
                getUserSelectListFailure: false,
                getUserSelectListError: null,
            }
        case USER_REQUEST.GET_USER_SELECT_LIST_FAILURE:
            return {
                ...state,
                getUserSelectListLoading: false,
                getUserSelectListSuccess: false,
                getUserSelectListFailure: true,
                getUserSelectListError: action.payload
            }
        case USER_REQUEST.GET_USER_SELECT_LIST_SUCCESS:
            return {
                ...state,
                getUserSelectListLoading: false,
                getUserSelectListSuccess: true,
                getUserSelectListFailure: false,
                getUserSelectListError: null,
                userSelectList: action.payload
            }
        default:
            return state;
    }
};