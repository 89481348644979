import { LOCATION_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
    getLocationsSelectListLoading: false,
    getLocationsSelectListSuccess: false,
    getLocationsSelectListFailure: false,
    getLocationsSelectListError: null,
    locationsSelectList: [],
};

export const locationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOCATION_REQUEST.GET_LOCATION_SELECT_LIST_REQUEST:
            return {
                ...state,
                getLocationSelectListLoading: true,
                getLocationSelectListSuccess: false,
                getLocationSelectListFailure: false,
                getLocationSelectListError: null,
            }
        case LOCATION_REQUEST.GET_LOCATION_SELECT_LIST_FAILURE:
            return {
                ...state,
                getLocationSelectListLoading: false,
                getLocationSelectListSuccess: false,
                getLocationSelectListFailure: true,
                getLocationSelectListError: action.payload
            }
        case LOCATION_REQUEST.GET_LOCATION_SELECT_LIST_SUCCESS:
            return {
                ...state,
                getLocationSelectListLoading: false,
                getLocationSelectListSuccess: true,
                getLocationSelectListFailure: false,
                getLocationSelectListError: null,
                locationsSelectList: action.payload
            }
        default:
            return state;
    }
};