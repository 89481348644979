import { DEPARTMENT_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
    getDepartmentListLoading: false,
    getDepartmentListSuccess: false,
    getDepartmentListFailure: false,
    getDepartmentListError: null,
    departmentList: [],

    addNewDepartmentLoading: false,
    addNewDepartmentSuccess: false,
    addNewDepartmentFailure: false,
    addNewDepartmentError: null,

    updateDepartmentLoading: false,
    updateDepartmentSuccess: false,
    updateDepartmentFailure: false,
    updateDepartmentError: null,

    deleteDepartmentLoading: false,
    deleteDepartmentSuccess: false,
    deleteDepartmentFailure: false,
    deleteDepartmentError: null,

    getDepartmentSelectListLoading: false,
    getDepartmentSelectListSuccess: false,
    getDepartmentSelectListFailure: false,
    getDepartmentSelectListError: null,
    departmentSelectList: [],
};

export const departmentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DEPARTMENT_REQUEST.GET_ALL_DEPARTMENT_LIST_REQUEST:
            return {
                ...state,
                getDepartmentListLoading: true,
                getDepartmentListSuccess: false,
                getDepartmentListFailure: false,
                getDepartmentListError: null,
            }
        case DEPARTMENT_REQUEST.GET_ALL_DEPARTMENT_LIST_FAILURE:
            return {
                ...state,
                getDepartmentListLoading: false,
                getDepartmentListSuccess: false,
                getDepartmentListFailure: true,
                getDepartmentListError: action.payload
            }
        case DEPARTMENT_REQUEST.GET_ALL_DEPARTMENT_LIST_SUCCESS:
            return {
                ...state,
                getDepartmentListLoading: false,
                getDepartmentListSuccess: true,
                getDepartmentListFailure: false,
                getDepartmentListError: null,
                departmentList: action.payload
            }
        case DEPARTMENT_REQUEST.ADD_NEW_DEPARTMENT_REQUEST:
            return {
                ...state,
                addNewDepartmentLoading: true,
                addNewDepartmentSuccess: false,
                addNewDepartmentFailure: false,
                addNewDepartmentError: null,
            }
        case DEPARTMENT_REQUEST.ADD_NEW_DEPARTMENT_FAILURE:
            return {
                ...state,
                addNewDepartmentLoading: false,
                addNewDepartmentSuccess: false,
                addNewDepartmentFailure: true,
                addNewDepartmentError: action.payload
            }
        case DEPARTMENT_REQUEST.ADD_NEW_DEPARTMENT_SUCCESS:
            let departmentList = state.departmentList
            departmentList.push(action.payload)
            return {
                ...state,
                addNewDepartmentLoading: false,
                addNewDepartmentSuccess: true,
                addNewDepartmentFailure: false,
                addNewDepartmentError: null,
                departmentList: departmentList
            }
        case DEPARTMENT_REQUEST.UPDATE_DEPARTMENT_REQUEST:
            return {
                ...state,
                updateDepartmentLoading: true,
                updateDepartmentSuccess: false,
                updateDepartmentFailure: false,
                updateDepartmentError: null,
            }
        case DEPARTMENT_REQUEST.UPDATE_DEPARTMENT_FAILURE:
            return {
                ...state,
                updateDepartmentLoading: false,
                updateDepartmentSuccess: false,
                updateDepartmentFailure: true,
                updateDepartmentError: action.payload
            }
        case DEPARTMENT_REQUEST.UPDATE_DEPARTMENT_SUCCESS:
            let departmentUpdateList = state.departmentList
            for (let i = 0; i < departmentUpdateList.length; i++) {
                if (action.payload.departmentId === departmentUpdateList[i].departmentId) {
                    departmentUpdateList[i] = action.payload
                }
            }
            return {
                ...state,
                updateDepartmentLoading: false,
                updateDepartmentSuccess: true,
                updateDepartmentFailure: false,
                updateDepartmentError: null,
                departmentList: departmentUpdateList
            }
        case DEPARTMENT_REQUEST.DELETE_DEPARTMENT_REQUEST:
            return {
                ...state,
                deleteDepartmentLoading: true,
                deleteDepartmentSuccess: false,
                deleteDepartmentFailure: false,
                deleteDepartmentError: null,
            }
        case DEPARTMENT_REQUEST.DELETE_DEPARTMENT_FAILURE:
            return {
                ...state,
                deleteDepartmentLoading: false,
                deleteDepartmentSuccess: false,
                deleteDepartmentFailure: true,
                deleteDepartmentError: action.payload
            }
        case DEPARTMENT_REQUEST.DELETE_DEPARTMENT_SUCCESS:
            let departmentDeleteList = state.departmentList
            for (let i = 0; i < departmentDeleteList.length; i++) {
                if (action.payload === departmentDeleteList[i].departmentId) {
                    departmentDeleteList.splice(i, 1);
                }
            }
            return {
                ...state,
                deleteDepartmentLoading: false,
                deleteDepartmentSuccess: true,
                deleteDepartmentFailure: false,
                deleteDepartmentError: null,
                departmentList: departmentDeleteList
            }
        case DEPARTMENT_REQUEST.GET_DEPARTMENT_SELECT_LIST_REQUEST:
            return {
                ...state,
                getDepartmentSelectListLoading: true,
                getDepartmentSelectListSuccess: false,
                getDepartmentSelectListFailure: false,
                getDepartmentSelectListError: null,
            }
        case DEPARTMENT_REQUEST.GET_DEPARTMENT_SELECT_LIST_FAILURE:
            return {
                ...state,
                getDepartmentSelectListLoading: false,
                getDepartmentSelectListSuccess: false,
                getDepartmentSelectListFailure: true,
                getDepartmentSelectListError: action.payload
            }
        case DEPARTMENT_REQUEST.GET_DEPARTMENT_SELECT_LIST_SUCCESS:
            return {
                ...state,
                getDepartmentSelectListLoading: false,
                getDepartmentSelectListSuccess: true,
                getDepartmentSelectListFailure: false,
                getDepartmentSelectListError: null,
                departmentSelectList: action.payload
            }
        default:
            return state;
    }
};