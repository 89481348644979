// import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import EventsReducer from './eventsReducer';

// const reducers = combineReducers({
//     theme: Theme,
//     auth: Auth,
//     events: EventsReducer
// });

// export default reducers;


import { combineReducers } from "redux";
import { departmentReducer } from './departmentReducer';
import { employeeReducer } from './employeeReducer';
import { locationReducer } from './locationReducer';
import { commonReducer } from './commonReducer';
import { teamReducer } from './teamReducer';

export const rootReducer = combineReducers({
    theme: Theme,
    auth: Auth,
    events: EventsReducer,
    department: departmentReducer,
    employee: employeeReducer,
    location: locationReducer,
    common: commonReducer,
    team: teamReducer,
});