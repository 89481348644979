import React, { useState } from "react";
import {
  BsBell,
  BsCloudUpload,
  BsFillGrid3X3GapFill,
  BsGear,
  BsGraphUp,
  BsJoystick,
  BsReply,
} from "react-icons/bs";
import { faCog, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
// import { IMAGES } from "../../../images";
import {
  AiOutlineCaretLeft,
  AiOutlineCheckCircle,
  AiOutlineDollarCircle,
  AiOutlineHome,
  AiOutlineSetting,
  AiOutlineTeam,
  AiOutlineUser,
} from "react-icons/ai";
import { Button, Dropdown, Form, NavDropdown } from "react-bootstrap";
// import { getInitials } from "../../../genericAction";
// import { useAuth } from "../../../../Navigation/Auth/ProvideAuth";
import { useRef } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
// import { SignOut } from "../../SignOut";
// import { TASK_REQUEST, request } from "../../../../redux/actions/utilities";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory, useParams } from "react-router-dom";
import { Link, useHistory, useParams } from "react-router-dom";
import { IoIosOptions } from "react-icons/io";
import { PATH } from "config";
import { IMAGES } from "assets/images";
import { SignOut } from "../SignOut";
import { FiLogOut } from "react-icons/fi";
import { APP_PREFIX_PATH } from "configs/AppConfig";

function UpdatedSideBar() {
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  let history = useHistory();
  const [clicked, setClicked] = useState(false);

  // const auth = useAuth();
  const purchasedSecRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);

  const upDateStateAction = () => {
    setClicked(!clicked);
  };
  const handleClickOutside = (e) => {
    if (
      purchasedSecRef.current !== null &&
      !purchasedSecRef?.current?.contains(e.target)
    ) {
      setClicked(false);
    }
  };
  const NotificationButton = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  const goToPage = (link) => {
    window.location.href = link;
  };

  const openTaskBoardModal = () => {
    // dispatch(request(TASK_REQUEST.OPEN_ADD_BOARD_MODAL));
  };

  let params = useParams();
  // const { currentProject } = useSelector((state) => state.project);
  return (
    <div className="new-app-layout bg-white">
      <div className="bg-white">
        <div className={` px-sm-2 px-0 updated-sidebar maxWidth-sidebar`}>
          <div className="d-flex flex-column align-items-center justify-content-between pt-2 text-white min-vh-100 h-100">
            <div>
              <ul
                className="nav nav-pills mb-0 flex-column align-items-center align-items-sm-center"
                id="menu"
              >
                <li className="mt-0">
                  <img
                    className="img-fluid app-logo-width"
                    src={IMAGES.APPLOGO}
                  />
                </li>
                <li
                  className="mt-4 side-link"
                  onClick={() => goToPage("https://covis.ai/home")}
                >
                  {console.log(
                    "history?.location?.pathname",
                    history?.location?.pathname
                  )}

                  <AiOutlineHome
                    className="sideNave-icon icons side-link p-2"
                    size={22}
                  />
                </li>
                <li
                  className={`mt-3 side-link position-relative-li ${
                    clicked && "active"
                  }`}
                >
                  {clicked ? (
                    <>
                      <BsFillGrid3X3GapFill
                        className="icon-nav sideNave-icon icons"
                        size={20}
                      />
                      <AiOutlineCaretLeft
                        className=" arrw-icon-styling"
                        // size={25}
                      />
                    </>
                  ) : (
                    <BsFillGrid3X3GapFill
                      size={20}
                      className=" sideNave-icon icons"
                      onClick={() => upDateStateAction()}
                    />
                  )}
                  {clicked && (
                    <div
                      className="sidebar-item-clicked bg-white test_this"
                      ref={purchasedSecRef}
                    >
                      <div className="backdrop bg-white pb-3 pt-2 px-4">
                        <span className="text-muted my-purchased-text ">
                          My Apps
                        </span>
                        <div className="d-flex mt-3">
                          <div
                            className="human-resourses p-1 font-size-10"
                            style={{ background: "#3EB5FE" }}
                            onClick={() =>
                              goToPage("https://covis.ai/dashboard/pm")
                            }
                          >
                            <div className="px-2 pt-2 pb-1">
                              <img
                                src={IMAGES.PROJECT_MANAGEMENT_ICON}
                                alt="pmi"
                              />
                              <br />
                            </div>
                            <span className="font-size-10 mb-2  text-white">
                              Project Management
                            </span>
                          </div>
                          {/* <div className="human-resourses bg-warning font-size-10">
                            <div className=" circle-div p-2">
                              <BsJoystick className="text-warning" />
                              <br />
                            </div>
                            <span className="font-size-10 mb-2">
                              Project management
                            </span>
                          </div> */}
                          <Link
                            to={PATH.MAINDASHBOARD}
                            onClick={() => setClicked(false)}
                          >
                            <div
                              className="human-resourses font-size-10 mx-3"
                              style={{ background: "#0AF090" }}
                            >
                              <div className="p-2">
                                <img src={IMAGES.HRMANAGER_ICON} alt="pmi" />
                                <br />
                              </div>
                              <span className="font-size-10 mb-2 text-white">
                                Human Resource Management
                              </span>
                            </div>
                          </Link>
                          {/* <div
                            className="human-resourses bg-success font-size-10  mx-3"
                            onClick={() =>
                              goToPage("https://hrm.visioncollab.com/app")
                            }
                          >
                            <div className=" circle-div p-2 mb-3">
                              <AiOutlineTeam className="text-success" />
                              <br />
                            </div>
                            <span className="font-size-10 mb-2 p-1">Human Resource Management</span>
                          </div> */}
                          {/* <div
                            className="human-resourses feedback-system font-size-10 "
                            onClick={() =>
                              goToPage("https://tracker.visioncollab.com")
                            }
                          >
                            <div className=" circle-div p-2">
                              <BsReply className="feedback-system-icon-color" />
                              <br />
                            </div>
                            <span className="font-size-10 mb-2">
                              Resources Tracker
                            </span>
                          </div> */}
                          <div
                            className="human-resourses p-1 feedback-system font-size-10"
                            style={{ background: "#A27DC0" }}
                            onClick={() =>
                              goToPage("https://tracker.visioncollab.com")
                            }
                          >
                            <div className="p-2">
                              <img
                                src={IMAGES.RESOURCE_TRACKER_ICON}
                                alt="pmi"
                              />
                              <br />
                            </div>
                            <span className="font-size-10 mb-2">
                              Resource Tracker
                            </span>
                          </div>
                        </div>
                        <div className="d-flex mt-3 justify-content-center">
                          <div
                            className="human-resourses font-size-10 p-1 mr-3"
                            style={{ background: "#FE8858" }}
                            onClick={() =>
                              goToPage("https://fms.visioncollab.com")
                            }
                          >
                            <div className="p-2">
                              <img
                                src={IMAGES.FINANCIAL_SYSTEM_ICON}
                                alt="pmi"
                              />
                              <br />
                            </div>
                            <span className="font-size-10 mb-2">
                              Financial System
                            </span>
                          </div>

                          <div
                            className="human-resourses p-1 font-size-10 "
                            style={{ background: "#FDD84F" }}
                            // onClick={() => {
                            //   history.push(PATH.REPORTFILES);
                            //   setClicked(false);
                            // }}
                            onClick={() =>
                              goToPage("https://covis.ai/ReportFiles")
                            }
                          >
                            <div className="p-2">
                              <img src={IMAGES.FILE_CLOUD_ICON} alt="pmi" />
                              <br />
                            </div>
                            <span className="font-size-10 mb-2">
                              File Cloud
                            </span>
                          </div>
                          {/* <div
                            className="human-resourses bg-danger font-size-10 mr-3"
                            onClick={() =>
                              goToPage("https://fms.visioncollab.com")
                            }
                          >
                            <div className=" circle-div p-2">
                              <BsGraphUp className="text-danger" />
                              <br />
                            </div>
                            <span className="font-size-10 mb-2">
                              Finance Management
                            </span>
                          </div>

                          <div
                            className="human-resourses settings-bg-color font-size-10"
                            onClick={() => {
                              history.push(PATH.REPORTFILES);
                              setClicked(false);
                            }}
                          >
                            <div className=" circle-div p-2 mb-3">
                              <BsCloudUpload className="text-info" />
                              <br />
                            </div>
                            <span className="font-size-10 mb-2">
                              File Cloud
                            </span>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  )}
                </li>
                <li className="mt-3 side-link">
                  <Dropdown
                    className="align-self-center project-options"
                    ref={dropdownRef}
                  >
                    <Dropdown.Toggle
                      id="dropdown-custom-components"
                      as={NotificationButton}
                    >
                      <BsBell
                        className="icon-nav sideNave-icon icons side-link p-2"
                        size={20}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      style={{ minWidth: "23rem" }}
                      className="no-padding"
                      align="right"
                    >
                      <NotificationSection />
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li
                  onClick={() => goToPage("https://covis.ai/pricing")}
                  className={`mt-3 side-link ${
                    history?.location?.pathname === PATH.PRICING && "active"
                  }`}
                >
                  <AiOutlineDollarCircle
                    size={20}
                    className="sideNave-icon icons"
                  />
                </li>
                {history.location.pathname.startsWith("/Task/") && (
                  <li className="mt-2">
                    <Dropdown className="align-self-center project-options">
                      <NavDropdown
                        alignRight
                        title={
                          // <FontAwesomeIcon className="icon-nav" icon={faCog} />
                          <IoIosOptions
                            className="sideNave-icon icons"
                            color="white"
                            size={20}
                          />
                        }
                        id="collasible-nav-dropdown"
                      >
                        <NavDropdown.Item onClick={() => openTaskBoardModal()}>
                          {"Create Board "}
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#">{"Builds"}</NavDropdown.Item>
                        <NavDropdown.Item
                        // onClick={() =>
                        //   history.push({
                        //     pathname: PATH.PROJECTSETTING.replace(
                        //       ":projectId"
                        //       ,
                        //       params?.id
                        //     ),
                        //     state: {
                        //       value: currentProject,
                        //     },
                        //   })
                        // }
                        >
                          {"Edit Project"}
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#">
                          {"Move to Trash"}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                        // onClick={() =>
                        //   dispatch(request(TASK_REQUEST.OPEN_INVITE_MODAL))
                        // }
                        >
                          {"Invite"}
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Dropdown>
                  </li>
                )}
              </ul>
              <ul
                className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-center"
                id="menu"
              >
                {/* {auth.user.userRoleId == "1" && (
              <>
                <li className="mt-2" onClick={() => history.push(PATH.PRICING)}>
                  <AiOutlineDollarCircle size={30} />
                </li>
                <li>
                  <Dropdown className="align-self-center project-options">
                    <NavDropdown
                      alignRight
                      title={
                        <FontAwesomeIcon className="icon-nav" icon={faCog} />
                      }
                      id="collasible-nav-dropdown"
                    >
                      <NavDropdown.Item onClick={() => history.push(PATH.TRASH)}>
                        Trash
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => history.push(PATH.USER_ROLES)}>
                        User Role
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => history.push(PATH.MANAGEMENU)}>
                        Manage Menus
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => history.push(PATH.ROLEBASEDMENUS)}>
                        Assign Role Based Menus
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => history.push(PATH.ORGANIZATION)}>
                        Organizations
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => history.push(PATH.MANAGEUSER)}>
                        New User
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => history.push(PATH.CLIENTS)}>
                        Clients
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => history.push(PATH.PROJECTSETTINGS)}>
                        Project Settings
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Dropdown>
                </li>
              </>
            )} */}

                <li className="mt-3 side-link">
                  <Dropdown className="align-self-center project-options p-0 settings-dropdown">
                    <NavDropdown
                      className="new-setting-item"
                      alignRight
                      title={
                        <AiOutlineSetting
                          className="sideNave-icon icons"
                          size={22}
                        />
                      }
                      id=""
                    >
                      <NavDropdown.Item
                        onClick={() =>
                          history.push({
                            pathname: `${APP_PREFIX_PATH}/trash`,
                          })
                        }
                      >
                        Trash
                      </NavDropdown.Item>
                      {/* <NavDropdown.Item
                      onClick={() => history.push(PATH.USER_ROLES)}
                    >
                      User Role
                    </NavDropdown.Item> */}
                      {/* <NavDropdown.Item
                        onClick={() => history.push(PATH.MANAGEMENU)}
                      >
                        Manage Menus
                      </NavDropdown.Item> */}
                      {/* <NavDropdown.Item
                        onClick={() => history.push(PATH.ROLEBASEDMENUS)}
                      >
                        Assign Role Based Menus
                      </NavDropdown.Item> */}
                      <NavDropdown.Item
                        onClick={() =>
                          history.push({
                            pathname: `${APP_PREFIX_PATH}/organization`,
                          })
                        }
                      >
                        Organizations
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() =>
                          history.push({
                            pathname: `${APP_PREFIX_PATH}/manage-user`,
                          })
                        }
                      >
                        Users
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() =>
                          history.push({
                            pathname: `${APP_PREFIX_PATH}/clients`,
                          })
                        }
                      >
                        Clients
                      </NavDropdown.Item>
                      {/* <NavDropdown.Item
                        onClick={() => history.push(PATH.PROJECTSETTINGS)}
                      >
                        Project Settings
                      </NavDropdown.Item> */}
                    </NavDropdown>
                  </Dropdown>
                </li>
                <li className="mt-4">
                  <Dropdown className="align-self-center">
                    <Dropdown.Toggle
                      id="dropdown-custom-components"
                      as={NotificationButton}
                    >
                      <div className="btn-hi align-items-center">
                        MS
                        {/* {getInitials(
                      `${auth.user.firstName} ${auth.user.lastName}`
                    )} */}
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      style={{ minWidth: "18rem" }}
                      align="right"
                      className="sign-out-menu"
                    >
                      <div className="d-flex flex-column align-items-center">
                        <div className="d-flex mx-2 align-items-lg-end">
                          <div className="UserImage my-profile-img">
                            <p className="initial-name">
                              MS
                              {/* {getInitials(
                            `${auth.user.firstName} ${auth.user.lastName}`
                          )} */}
                            </p>
                          </div>
                          <div className="w-100 text-center ml-1">
                            <h5 className="text-dark mt-3 text-left ml-2 fw9">
                              {`Muhammad Shafiq`}
                            </h5>
                            <p className="sign-out-Profile">{`Member Since Jul 15, 2021`}</p>
                          </div>
                        </div>
                        <div className="border-bottom w-100 mt-3"></div>
                        <div className="w-100 mt-3 pl-4">
                          <div
                            variant="light"
                            className="border-0 d-flex align-content-center mb-2"
                          >
                            <AiOutlineUser className="mr-2 user-profile-out f-18" />
                            <span className="sign-out-Profile ">
                              View Profile
                            </span>
                          </div>
                          <div
                            variant="light"
                            className="border-0 d-flex align-content-center align-item-center mb-2 mt-3"
                          >
                            <span className="">
                              <FiLogOut className="mr-2 user-profile-out f-18" />
                              {/* <img src={IMAGES.SIGNOUTIMG} className="mr-2 " /> */}
                            </span>
                            <SignOut className="text-dark sign-out-Profile" />
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
            {/* <div class="div-rotation d-flex align-items-center xeven-checkbox">
          <Form>
            <Form.Check type="switch" id="custom-switch" label="" />
          </Form>
          <h6 className="mb-0 tenant-name">XEVEN SOLUTIONS</h6>
        </div> */}

            <div>
              <ul
                className="nav nav-pills flex-column align-items-center mb-5 nav-after"
                id="menu"
              >
                <li className="covis-side-link">
                  <Dropdown className="align-self-center project-options p-0 organization-dropdown">
                    <NavDropdown
                      className="new-setting-item"
                      alignRight
                      title={
                        <div className="covis-logo-size">
                          <img src={IMAGES.APPLOGO1} alt="Covis Logo" />
                        </div>
                      }
                      id=""
                    >
                      <NavDropdown.Item>Xeven Health</NavDropdown.Item>
                      <NavDropdown.Item>Covis</NavDropdown.Item>
                    </NavDropdown>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdatedSideBar;
const NotificationSection = (props) => {
  const notifications = [
    {
      image: IMAGES.NOTIFICATION1,
      title: "New Request",
      tinyhead: "Iftar riaz send you a request",
      date: "Today",
    },
    {
      image: IMAGES.NOTIFICATION2,
      title: "Request Approved",
      tinyhead: "Eisa Malik send you a request",
      date: "Yesterday",
    },
    {
      image: IMAGES.NOTIFICATION3,
      title: "New Message",
      tinyhead: "Eisa Malik send you a request",
      date: new Date().toDateString(),
    },
    {
      image: IMAGES.NOTIFICATION4,
      title: "New Message",
      tinyhead: "Ali approved request",
      date: new Date().toDateString(),
    },
    {
      image: IMAGES.NOTIFICATION5,
      title: "New Message",
      tinyhead: "Eisa Malik send you a request",
      date: new Date().toDateString(),
    },
  ];
  const handleCloseDropdown = () => {
    const dropdownButton = document.querySelector(".dropdown");
    dropdownButton.click();
  };
  return (
    <>
      <div className="notification-panel-header">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-baseline">
              <h5 className="Notifications-center">Notifications</h5>
              <div className="d-flex align-items-center">
                <p className="Mark-read mr-2">Mark all as read</p>
                <AiOutlineCheckCircle className="Mark-read" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid notification-panel-body">
        <div className="w-100">
          {notifications &&
            notifications?.map((notification, idx) => {
              return (
                <div className="cursor-pointer notif-hover" key={idx}>
                  <SingleNotification notification={notification} />
                  {idx !== notifications.length - 1 && (
                    <hr className="mb-3 mt-2" />
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <div className="notification-panel-footer py-3">
        <div className="row">
          <div className="col-12 justify-content-center d-flex">
            <Link to={`${APP_PREFIX_PATH}/notification/setting`}>
              <button
                className="all-notification"
                onClick={handleCloseDropdown}
              >
                {"Notifications Settings"}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
const SingleNotification = (props) => {
  return (
    <>
      <div className="row mb-1">
        <div className="col-1 align-self-center">
          <img
            src={props.notification.image}
            icon={faExclamationCircle}
            className="Notification-images"
            style={{
              fontSize: "22px",
            }}
            alt=""
          />
        </div>
        <div className="col-10 pr-0">
          <div className="d-flex justify-content-between align-item-center ml-4">
            <div className="">
              <p className="no-margin massages_history">
                {props.notification.title}
              </p>
              <p className="messsage_request">{props.notification.tinyhead}</p>
            </div>
            <p className="no-margin font-200 text-muted text-right">
              {props.notification.date}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
