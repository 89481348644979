// import { createStore, applyMiddleware, compose } from "redux";
// import reducers from "../reducers";
// import createSagaMiddleware from "redux-saga";
// import rootSaga from "../sagas/index";

// const sagaMiddleware = createSagaMiddleware();

// const middlewares = [sagaMiddleware];

// function configureStore(preloadedState) {
 
//   const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//   const store = createStore(reducers, preloadedState, composeEnhancers(
//     applyMiddleware(...middlewares)
//   ));

//   sagaMiddleware.run(rootSaga);

//   if (module.hot) {
//     module.hot.accept("../reducers/index", () => {
//       const nextRootReducer = require("../reducers/index");
//       store.replaceReducer(nextRootReducer);
//     });
//   }

//   return store;
// }

// const store = configureStore();

// export default store;


import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { rootReducer } from "../reducers";

const devTools = process.env.NODE_ENV !== "production" && window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
    : (a) => a;

const middleware = compose(applyMiddleware(thunk), devTools);
const store = createStore(rootReducer, middleware);
export { store };