import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Layout, Select } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import Logo from './Logo';
import NavPanel from './NavPanel';
import NavSearch from './NavSearch';
import { toggleCollapsedNav, onMobileNavToggle } from 'redux/actions/Theme';
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import utils from 'utils'
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "antd";
import { Router, Route, Switch, Link, useHistory } from "react-router-dom";
// import RecruitmentDashboard from "../../views/app-views/Dashboard/BuisnessDevelopment"
import { PATH } from "../../config";
// import {MainDashboard} from "views/app-views/Dashboard/MainDashboard";
const { Header } = Layout;

export const HeaderNav = props => {
  let history = useHistory()
  const { navCollapsed, mobileNav, navType, headerNavColor, isMobile, currentTheme, direction } = props;
  const [searchActive, setSearchActive] = useState(false)
  const dispatch = useDispatch();
  const onSearchClose = () => {
    setSearchActive(false)
  }

  const onToggle = () => {
    if (!isMobile) {
      dispatch(toggleCollapsedNav(!navCollapsed))
    } else {
      dispatch(onMobileNavToggle(!mobileNav))
    }
  }

  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff')
    }
    return utils.getColorContrast(headerNavColor)
  }
  const navMode = mode()
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px'
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    } else {
      return `${SIDE_NAV_WIDTH}px`
    }
  }

  useEffect(() => {
    if (!isMobile) {
      onSearchClose()
    }
  })

  return (
    <Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor }}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={navMode} />
        <div className="nav" style={{ width: `calc(33% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal d-flex">
              {
                isNavTop && !isMobile ?
                  null
                  :

                  <>
                    <li className="ant-menu-item ant-menu-item-only-child" onClick={() => { onToggle() }}>
                      {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon" /> : <MenuFoldOutlined className="nav-icon" />}
                    </li>
                    {
                      ((history.location.pathname === PATH.MAINDASHBOARD) || (history.location.pathname === PATH.RECRUITMENTDASHBOARD))
                      &&
                      <li>
                        <Col sm={4}>
                          <Select className="DropDown-Dashboard" placeholder="Dashboard" onChange={(e) => {
                            history.push(e)
                          }}>
                            <Select.Option value={PATH.MAINDASHBOARD}>
                              Main Dashboard
                            </Select.Option>
                            <Select.Option className="d-flex" value={PATH.RECRUITMENTDASHBOARD}>
                              Recruitment Dashboard
                            </Select.Option>
                          </Select>
                        </Col>
                      </li>
                    }

                  </>
              }
            </ul>
          </div>
          {
            ((history.location.pathname !== PATH.MAINDASHBOARD) && (history.location.pathname !== PATH.RECRUITMENTDASHBOARD))
            &&
            <div className="d-flex flex-column justify-content-center flex-grow-1 ml-2" >
              <h2 id="pageTitle" className="mb-0">{props.title}</h2>
            </div>
          }
          <div className="nav-right">
            <NavPanel direction={direction} />
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>

        <Row>

        </Row>
      </div>
    </Header>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction } = theme;
  return { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction }
};

export default connect(mapStateToProps, { toggleCollapsedNav, onMobileNavToggle })(HeaderNav);