import { EMPLOYEE_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
    getEmployeeSelectListLoading: false,
    getEmployeeSelectListSuccess: false,
    getEmployeeSelectListFailure: false,
    getEmployeeSelectListError: null,
    employeeSelectList: [],

    getAllEmployeeListLoading: false,
    getAllEmployeeListSuccess: false,
    getAllEmployeeListFailure: false,
    getAllEmployeeListError: null,
    allEmployeeList: [],
    totalEmployeeRecords : null,

    addNewEmployeeLoading: false,
    addNewEmployeeSuccess: false,
    addNewEmployeeFailure: false,
    addNewEmployeeError: null,

    deleteEmployeeLoading: false,
    deleteEmployeeSuccess: false,
    deleteEmployeeFailure: false,
    deleteEmployeeError: null,

    getSingleEmployeeLoading: false,
    getSingleEmployeeSuccess: false,
    getSingleEmployeeFailure: false,
    getSingleEmployeeError: null,
    singleEmployee: null,

    updateEmployeeLoading: false,
    updateEmployeeSuccess: false,
    updateEmployeeFailure: false,
    updateEmployeeError: null,
};

export const employeeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EMPLOYEE_REQUEST.GET_EMPLOYEE_SELECT_LIST_REQUEST:
            return {
                ...state,
                getEmployeeSelectListLoading: true,
                getEmployeeSelectListSuccess: false,
                getEmployeeSelectListFailure: false,
                getEmployeeSelectListError: null,
            }
        case EMPLOYEE_REQUEST.GET_EMPLOYEE_SELECT_LIST_FAILURE:
            return {
                ...state,
                getEmployeeSelectListLoading: false,
                getEmployeeSelectListSuccess: false,
                getEmployeeSelectListFailure: true,
                getEmployeeSelectListError: action.payload
            }
        case EMPLOYEE_REQUEST.GET_EMPLOYEE_SELECT_LIST_SUCCESS:
            return {
                ...state,
                getEmployeeSelectListLoading: false,
                getEmployeeSelectListSuccess: true,
                getEmployeeSelectListFailure: false,
                getEmployeeSelectListError: null,
                employeeSelectList: action.payload
            }
        case EMPLOYEE_REQUEST.GET_ALL_EMPLOYEES_LIST_REQUEST:
            return {
                ...state,
                getAllEmployeeListLoading: true,
                getAllEmployeeListSuccess: false,
                getAllEmployeeListFailure: false,
                getAllEmployeeListError: null,
                totalEmployeeRecords:null,
            }
        case EMPLOYEE_REQUEST.GET_ALL_EMPLOYEES_LIST_FAILURE:
            return {
                ...state,
                getAllEmployeeListLoading: false,
                getAllEmployeeListSuccess: false,
                getAllEmployeeListFailure: true,
                getAllEmployeeListError: action.payload,
                totalEmployeeRecords:null
            }
        case EMPLOYEE_REQUEST.GET_ALL_EMPLOYEES_LIST_SUCCESS:
            return {
                ...state,
                getAllEmployeeListLoading: false,
                getAllEmployeeListSuccess: true,
                getAllEmployeeListFailure: false,
                getAllEmployeeListError: null,
                allEmployeeList: action.payload.records,
                totalEmployeeRecords: action.payload.totalRecords
            }
        case EMPLOYEE_REQUEST.ADD_NEW_EMPLOYEE_REQUEST:
            return {
                ...state,
                addNewEmployeeLoading: true,
                addNewEmployeeSuccess: false,
                addNewEmployeeFailure: false,
                addNewEmployeeError: null,
            }
        case EMPLOYEE_REQUEST.ADD_NEW_EMPLOYEE_FAILURE:
            return {
                ...state,
                addNewEmployeeLoading: false,
                addNewEmployeeSuccess: false,
                addNewEmployeeFailure: true,
                addNewEmployeeError: action.payload
            }
        case EMPLOYEE_REQUEST.ADD_NEW_EMPLOYEE_SUCCESS:
            // let employeeListToAdd = state.allEmployeeList
            // employeeListToAdd.push(action.payload)
            return {
                ...state,
                addNewEmployeeLoading: false,
                addNewEmployeeSuccess: true,
                addNewEmployeeFailure: false,
                addNewEmployeeError: null,
                // allEmployeeList: action.payload
            }
        case EMPLOYEE_REQUEST.DELETE_EMPLOYEE_REQUEST:
            return {
                ...state,
                deleteEmployeeLoading: true,
                deleteEmployeeSuccess: false,
                deleteEmployeeFailure: false,
                deleteEmployeeError: null,
            }
        case EMPLOYEE_REQUEST.DELETE_EMPLOYEE_FAILURE:
            return {
                ...state,
                deleteEmployeeLoading: false,
                deleteEmployeeSuccess: false,
                deleteEmployeeFailure: true,
                deleteEmployeeError: action.payload
            }
        case EMPLOYEE_REQUEST.DELETE_EMPLOYEE_SUCCESS:
            let employeeDeleteList = state.allEmployeeList
            for (let i = 0; i < employeeDeleteList.length; i++) {
                if (action.payload === employeeDeleteList[i].employeeInfoId) {
                    employeeDeleteList.splice(i, 1);
                }
            }
            return {
                ...state,
                deleteEmployeeLoading: false,
                deleteEmployeeSuccess: true,
                deleteEmployeeFailure: false,
                deleteEmployeeError: null,
                allEmployeeList: employeeDeleteList
            }
        case EMPLOYEE_REQUEST.GET_SINGLE_EMPLOYEE_REQUEST:
            return {
                ...state,
                getSingleEmployeeLoading: true,
                getSingleEmployeeSuccess: false,
                getSingleEmployeeFailure: false,
                getSingleEmployeeError: null,
            }
        case EMPLOYEE_REQUEST.GET_SINGLE_EMPLOYEE_FAILURE:
            return {
                ...state,
                getSingleEmployeeLoading: false,
                getSingleEmployeeSuccess: false,
                getSingleEmployeeFailure: true,
                getSingleEmployeeError: action.payload
            }
        case EMPLOYEE_REQUEST.GET_SINGLE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                getSingleEmployeeLoading: false,
                getSingleEmployeeSuccess: true,
                getSingleEmployeeFailure: false,
                getSingleEmployeeError: null,
                singleEmployee: action.payload
            }
        case EMPLOYEE_REQUEST.RESET_SINGLE_EMPLOYEE_REQUEST:
            return {
                ...state,
                getSingleEmployeeLoading: false,
                getSingleEmployeeSuccess: false,
                getSingleEmployeeFailure: false,
                getSingleEmployeeError: null,
                singleEmployee: null
            }
        case EMPLOYEE_REQUEST.UPDATE_EMPLOYEE_REQUEST:
            return {
                ...state,
                updateEmployeeLoading: true,
                updateEmployeeSuccess: false,
                updateEmployeeFailure: false,
                updateEmployeeError: null,
            }
        case EMPLOYEE_REQUEST.UPDATE_EMPLOYEE_FAILURE:
            return {
                ...state,
                updateEmployeeLoading: false,
                updateEmployeeSuccess: false,
                updateEmployeeFailure: true,
                updateEmployeeError: action.payload
            }
        case EMPLOYEE_REQUEST.UPDATE_EMPLOYEE_SUCCESS:
            // let updateList = state.allEmployeeList
            // for (let i = 0; i < updateList.length; i++) {
            //     if (updateList[i].employeeInfoId === action.payload.employeeInfoId) {
            //         updateList[i] = action.payload
            //     }
            // }
            return {
                ...state,
                updateEmployeeLoading: false,
                updateEmployeeSuccess: true,
                updateEmployeeFailure: false,
                updateEmployeeError: null,
                // allEmployeeList: updateList,
                singleEmployee: null
            }
        default:
            return state;
    }
};