import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { PATH } from "../../config";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={PATH.MAINDASHBOARD}
          component={lazy(() => import(`./Dashboard/MainDashboard`))}
        />
        <Route
          path={PATH.RECRUITMENTDASHBOARD}
          component={lazy(() => import(`./Dashboard/Recruitment`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/people`}
          component={lazy(() => import(`./people`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings`}
          component={lazy(() => import(`./settings/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings-clients`}
          component={lazy(() => import(`./settings/clients`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings-manageMenu`}
          component={lazy(() => import(`./settings/manageMenu`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings-organization`}
          component={lazy(() => import(`./settings/organization`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings-projectManagement`}
          component={lazy(() => import(`./settings/projectManagement`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings-manageUser`}
          component={lazy(() => import(`./settings/manageUser`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings-roleBasedMenus`}
          component={lazy(() => import(`./settings/roleBasedMenus`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings-userRole`}
          component={lazy(() => import(`./settings/userRole`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/candidate`}
          component={lazy(() => import(`./candidate/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/employee/:id`}
          component={lazy(() => import(`./newEmployee`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/hiring`}
          component={lazy(() => import(`./hiring`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/hiring/job-opening/create`}
          component={lazy(() => import(`./hiring/create`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/hiring/job-opening/edit`}
          component={lazy(() => import(`./hiring/create`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/hiring/job-opening/view`}
          component={lazy(() => import(`./hiring/view`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/hiring/jobDetail/addCandidate`}
          component={lazy(() => import(`./hiring/candidate/add`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/hiring/job-opening/candidate/candidateDedails`}
          component={lazy(() => import(`./hiring/candidate/candidateDedails`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/hiring/job-opening/candidate`}
          component={lazy(() => import(`./hiring/candidate/TalentPoolView`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/hiring/board/addBoard`}
          component={lazy(() => import(`./hiring/board/AddBoard`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/applicants`}
          component={lazy(() => import(`./applicants`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/hiring/AddCondidate`}
          component={lazy(() =>
            import(`../app-views/hiring/updateView/AddCondidate`)
          )}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/hiring/jop-opening/add-job`}
          component={lazy(() =>
            import(`../app-views/hiring/updateView/AddNewJob`)
          )}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/myInfo`}
          component={lazy(() => import(`./myInfo`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/assets`}
          component={lazy(() => import(`./assets`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/asset-allocation`}
          component={lazy(() => import(`./assetsAllocation`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/attendance`}
          component={lazy(() => import(`./attendance`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/events`}
          component={lazy(() => import(`./events`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/departments`}
          component={lazy(() => import(`./departments`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/teams`}
          component={lazy(() => import(`./teams`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/team/details/:id`}
          component={lazy(() => import(`./teams/teamDetail`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/TeamEmployee`}
          component={lazy(() => import(`./teams/TeamEmployee`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/recruitment/jobs/listing`}
          component={lazy(() => import(`./recruitment/jobs/listing`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/recruitment/candidates/listing`}
          component={lazy(() => import(`./recruitment/candidates/listing`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/recruitment/jobs/jobdetails/index`}
          component={lazy(() => import(`./recruitment/jobs/jobdetails/index`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/recruitment/candidates/candidatedetails/index`}
          component={lazy(() =>
            import(`./recruitment/candidates/candidatedetails/index`)
          )}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/newEmployee/AddNewEmployee/index`}
          component={lazy(() => import(`./newEmployee/AddNewEmployee/index`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/attendance/AttendanceHistory/AttendanceDashboard`}
          component={lazy(() =>
            import(`./attendance/AttendanceHistory/AttendanceDashboard`)
          )}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/attendance/AttendanceHistory/DailyAttendance`}
          component={lazy(() =>
            import(`./attendance/AttendanceHistory/DailyAttendance`)
          )}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/attendance/AttendanceHistory/Late-Coming`}
          component={lazy(() =>
            import(`./attendance/AttendanceHistory/LateComing`)
          )}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/attendance/AttendanceHistory/LeaveRequest`}
          component={lazy(() =>
            import(`./attendance/AttendanceHistory/LeaveRequest`)
          )}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/attendance/AttendanceHistory/holiday`}
          component={lazy(() =>
            import(`./attendance/AttendanceHistory/Holiday`)
          )}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/hiring/jobDetail`}
          component={lazy(() => import(`./hiring/updateView/JobDetail`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/notification/setting`}
          component={lazy(() => import(`./NotificationSettings/index`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/trash`}
          component={lazy(() => import(`./Trash/index`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/manage-user`}
          component={lazy(() => import(`./NewUser/index`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/organization`}
          component={lazy(() => import(`./Organization/index`))}
          exact
        />
        <Route
          path={`${APP_PREFIX_PATH}/clients`}
          component={lazy(() => import(`./Clients/index`))}
          exact
        />
        <Redirect to={PATH.MAINDASHBOARD} />
        {/* <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} /> */}
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
