import { DashboardOutlined, TeamOutlined } from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

import { PATH } from "../config";

const dashBoardNavTree = [
  {
    key: "Main_Dashboard",
    path: PATH.MAINDASHBOARD,
    title: "Dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },

];

const peopleNavTree = [
  {
    key: "people",
    path: `${APP_PREFIX_PATH}/people`,
    title: "Employee",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
const hiringNavTree = [
  {
    key: "hiring",
    path: `${APP_PREFIX_PATH}/hiring`,
    title: "Hiring",
    icon: TeamOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const applicantsNavTree = [
  {
    key: "applicants",
    path: `${APP_PREFIX_PATH}/applicants`,
    title: "Applicants",
    icon: TeamOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const myInfoNavTree = [
  {
    key: "myInfo",
    path: `${APP_PREFIX_PATH}/myInfo`,
    title: "My Info",
    icon: TeamOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const assetsNavTree = [
  {
    key: "assets",
    path: `${APP_PREFIX_PATH}/assets`,
    title: "Assets",
    icon: TeamOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const eventsNavTree = [
  {
    key: "events",
    path: `${APP_PREFIX_PATH}/events`,
    title: "Events",
    icon: TeamOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const departmentsNavTree = [
  {
    key: "departments",
    path: `${APP_PREFIX_PATH}/departments`,
    title: "Departments",
    icon: TeamOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
const teamsNavTree = [
  {
    key: "teams",
    path: `${APP_PREFIX_PATH}/teams`,
    title: "Teams",
    icon: TeamOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const attendanceNavTree = [
  {
    key: "attendance",
    path: `${APP_PREFIX_PATH}/attendance`,
    title: "Attendance",
    icon: TeamOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "Attendance-menu",
        path: `${APP_PREFIX_PATH}/attendance/listing`,
        title: "Attendance",
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "Monthly-Attendance",
            path: `${APP_PREFIX_PATH}/attendance/AttendanceHistory/AttendanceDashboard`,
            title: "Attendance Dashboard",
            icon: TeamOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "Daily-Attendance",
            path: `${APP_PREFIX_PATH}/attendance/AttendanceHistory/DailyAttendance`,
            title: "Daily Attendance",
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [],
          },

          {
            key: "Leave-Requests",
            path: `${APP_PREFIX_PATH}/attendance/AttendanceHistory/LeaveRequest`,
            title: "Leave Request",
            icon: TeamOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "Late-Coming",
            path: `${APP_PREFIX_PATH}/attendance/AttendanceHistory/LateComing`,
            title: "Late Coming",
            icon: TeamOutlined,
            breadcrumb: false,
            submenu: [],
          }
        ],
      },
    ],
  },
];

const recruitmentNavTree = [
  {
    key: "recruitment",
    path: `${APP_PREFIX_PATH}/recruitment`,
    title: "Hiring",
    icon: TeamOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "recruitment-jobs",
        path: `${APP_PREFIX_PATH}/recruitment/listing`,
        title: "Recruitment",
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "Recruitment_Dashboard",
            path: PATH.RECRUITMENTDASHBOARD,
            title: "Recruitment Dashboard",
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "recruitment-jobs-listing",
            path: `${APP_PREFIX_PATH}/recruitment/jobs/listing`,
            title: "Jobs",
            icon: TeamOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "recruitment-candidates-listing",
            path: `${APP_PREFIX_PATH}/recruitment/candidates/listing`,
            title: "Candidates",
            icon: TeamOutlined,
            breadcrumb: false,
            submenu: [],
          }
        ],
      },
    ],
  },
];

const navigationConfig = [
  ...dashBoardNavTree,
  ...hiringNavTree,
  ...applicantsNavTree,
  ...recruitmentNavTree,
  ...peopleNavTree,
  ...myInfoNavTree,
  ...assetsNavTree,
  ...attendanceNavTree,
  ...eventsNavTree,
  ...departmentsNavTree,
  ...teamsNavTree,
];

export default navigationConfig;
