function request(actionType) {
    return {
        type: actionType
    }
}
function success(actionType, response) {
    return {
        type: actionType,
        payload: response
    }
}
function failure(actionType, error) {
    return {
        type: actionType,
        payload: error
    }
}

const DEPARTMENT_REQUEST = {
    GET_ALL_DEPARTMENT_LIST_REQUEST: "GET_ALL_DEPARTMENT_LIST_REQUEST",
    GET_ALL_DEPARTMENT_LIST_SUCCESS: "GET_ALL_DEPARTMENT_LIST_SUCCESS",
    GET_ALL_DEPARTMENT_LIST_FAILURE: "GET_ALL_DEPARTMENT_LIST_FAILURE",

    ADD_NEW_DEPARTMENT_REQUEST: "ADD_NEW_DEPARTMENT_REQUEST",
    ADD_NEW_DEPARTMENT_SUCCESS: "ADD_NEW_DEPARTMENT_SUCCESS",
    ADD_NEW_DEPARTMENT_FAILURE: "ADD_NEW_DEPARTMENT_FAILURE",

    UPDATE_DEPARTMENT_REQUEST: "UPDATE_DEPARTMENT_REQUEST",
    UPDATE_DEPARTMENT_SUCCESS: "UPDATE_DEPARTMENT_SUCCESS",
    UPDATE_DEPARTMENT_FAILURE: "UPDATE_DEPARTMENT_FAILURE",

    DELETE_DEPARTMENT_REQUEST: "DELETE_DEPARTMENT_REQUEST",
    DELETE_DEPARTMENT_SUCCESS: "DELETE_DEPARTMENT_SUCCESS",
    DELETE_DEPARTMENT_FAILURE: "DELETE_DEPARTMENT_FAILURE",

    GET_DEPARTMENT_SELECT_LIST_REQUEST: "GET_DEPARTMENT_SELECT_LIST_REQUEST",
    GET_DEPARTMENT_SELECT_LIST_SUCCESS: "GET_DEPARTMENT_SELECT_LIST_SUCCESS",
    GET_DEPARTMENT_SELECT_LIST_FAILURE: "GET_DEPARTMENT_SELECT_LIST_FAILURE",
}

const EMPLOYEE_REQUEST = {
    GET_EMPLOYEE_SELECT_LIST_REQUEST: "GET_EMPLOYEE-SELECT_LIST_REQUEST",
    GET_EMPLOYEE_SELECT_LIST_SUCCESS: "GET_EMPLOYEE-SELECT_LIST_SUCCESS",
    GET_EMPLOYEE_SELECT_LIST_FAILURE: "GET_EMPLOYEE-SELECT_LIST_FAILURE",
    
    GET_ALL_EMPLOYEES_LIST_REQUEST: "GET_ALL_EMPLOYEES_LIST_REQUEST",
    GET_ALL_EMPLOYEES_LIST_SUCCESS: "GET_ALL_EMPLOYEES_LIST_SUCCESS",
    GET_ALL_EMPLOYEES_LIST_FAILURE: "GET_ALL_EMPLOYEES_LIST_FAILURE",

    DELETE_EMPLOYEE_REQUEST: "DELETE_EMPLOYEE_REQUEST",
    DELETE_EMPLOYEE_SUCCESS: "DELETE_EMPLOYEE_SUCCESS",
    DELETE_EMPLOYEE_FAILURE: "DELETE_EMPLOYEE_FAILURE",

    ADD_NEW_EMPLOYEE_REQUEST: "ADD_NEW_EMPLOYEE_REQUEST",
    ADD_NEW_EMPLOYEE_SUCCESS: "ADD_NEW_EMPLOYEE_SUCCESS",
    ADD_NEW_EMPLOYEE_FAILURE: "ADD_NEW_EMPLOYEE_FAILURE",

    GET_SINGLE_EMPLOYEE_REQUEST: "GET_SINGLE_EMPLOYEE_REQUEST",
    GET_SINGLE_EMPLOYEE_SUCCESS: "GET_SINGLE_EMPLOYEE_SUCCESS",
    GET_SINGLE_EMPLOYEE_FAILURE: "GET_SINGLE_EMPLOYEE_FAILURE",

    UPDATE_EMPLOYEE_REQUEST: "UPDATE_EMPLOYEE_REQUEST",
    UPDATE_EMPLOYEE_SUCCESS: "UPDATE_EMPLOYEE_SUCCESS",
    UPDATE_EMPLOYEE_FAILURE: "UPDATE_EMPLOYEE_FAILURE",

    RESET_SINGLE_EMPLOYEE_REQUEST: "RESET_SINGLE_EMPLOYEE_REQUEST",
}

const LOCATION_REQUEST = {
    GET_LOCATION_SELECT_LIST_REQUEST: "GET_LOCATION-SELECT_LIST_REQUEST",
    GET_LOCATION_SELECT_LIST_SUCCESS: "GET_LOCATION-SELECT_LIST_SUCCESS",
    GET_LOCATION_SELECT_LIST_FAILURE: "GET_LOCATION-SELECT_LIST_FAILURE",
}

const COUNTRY_REQUEST = {
    GET_COUNTRY_LIST_REQUEST: "GET_COUNTRY_LIST_REQUEST",
    GET_COUNTRY_LIST_SUCCESS: "GET_COUNTRY_LIST_SUCCESS",
    GET_COUNTRY_LIST_FAILURE: "GET_COUNTRY_LIST_FAILURE",
}

const STATE_REQUEST = {
    GET_STATE_LIST_REQUEST: "GET_STATE_LIST_REQUEST",
    GET_STATE_LIST_SUCCESS: "GET_STATE_LIST_SUCCESS",
    GET_STATE_LIST_FAILURE: "GET_STATE_LIST_FAILURE",
}

const CITY_REQUEST = {
    GET_CITY_LIST_REQUEST: "GET_CITY_LIST_REQUEST",
    GET_CITY_LIST_SUCCESS: "GET_CITY_LIST_SUCCESS",
    GET_CITY_LIST_FAILURE: "GET_CITY_LIST_FAILURE",
}

const USER_REQUEST = {
    GET_USER_SELECT_LIST_REQUEST: "GET_USER-SELECT_LIST_REQUEST",
    GET_USER_SELECT_LIST_SUCCESS: "GET_USER-SELECT_LIST_SUCCESS",
    GET_USER_SELECT_LIST_FAILURE: "GET_USER-SELECT_LIST_FAILURE",
}

const TEAM_REQUEST = {
    GET_TEAM_LIST_REQUEST: "GET_TEAM_LIST_REQUEST",
    GET_TEAM_LIST_SUCCESS: "GET_TEAM_LIST_SUCCESS",
    GET_TEAM_LIST_FAILURE: "GET_TEAM_LIST_FAILURE",

    BLOCK_UNBLOCK_TEAM_REQUEST: "BLOCK_UNBLOCK_TEAM_REQUEST",
    BLOCK_UNBLOCK_TEAM_SUCCESS: "BLOCK_UNBLOCK_TEAM_SUCCESS",
    BLOCK_UNBLOCK_TEAM_FAILURE: "BLOCK_UNBLOCK_TEAM_FAILURE",

    ADD_NEW_TEAM_REQUEST: "ADD_NEW_TEAM_REQUEST",
    ADD_NEW_TEAM_SUCCESS: "ADD_NEW_TEAM_SUCCESS",
    ADD_NEW_TEAM_FAILURE: "ADD_NEW_TEAM_FAILURE",

    GET_SINGLE_TEAM_DETAIL_REQUEST: "GET_SINGLE_TEAM_DETAIL_REQUEST",
    GET_SINGLE_TEAM_DETAIL_SUCCESS: "GET_SINGLE_TEAM_DETAIL_SUCCESS",
    GET_SINGLE_TEAM_DETAIL_FAILURE: "GET_SINGLE_TEAM_DETAIL_FAILURE",

    RESET_SINGLE_TEAM_DETAIL_REQUEST: "RESET_SINGLE_TEAM_DETAIL_REQUEST",

    ADD_TEAM_MEMBERS_REQUEST: "ADD_TEAM_MEMBERS_REQUEST",
    ADD_TEAM_MEMBERS_SUCCESS: "ADD_TEAM_MEMBERS_SUCCESS",
    ADD_TEAM_MEMBERS_FAILURE: "ADD_TEAM_MEMBERS_FAILURE",

    UPDATE_TEAM_DETAIL_REQUEST: "UPDATE_TEAM_DETAIL_REQUEST",
    UPDATE_TEAM_DETAIL_SUCCESS: "UPDATE_TEAM_DETAIL_SUCCESS",
    UPDATE_TEAM_DETAIL_FAILURE: "UPDATE_TEAM_DETAIL_FAILURE",

    DELETE_TEAM_MEMBER_REQUEST: "DELETE_TEAM_MEMBER_REQUEST",
    DELETE_TEAM_MEMBER_SUCCESS: "DELETE_TEAM_MEMBER_SUCCESS",
    DELETE_TEAM_MEMBER_FAILURE: "DELETE_TEAM_MEMBER_FAILURE",
}
export {
    request,
    success,
    failure,
    DEPARTMENT_REQUEST,
    EMPLOYEE_REQUEST,
    LOCATION_REQUEST,
    COUNTRY_REQUEST,
    STATE_REQUEST,
    CITY_REQUEST,
    USER_REQUEST,
    TEAM_REQUEST,
}
