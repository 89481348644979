import { Link, useHistory, useLocation } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import * as GoIcons from "react-icons/go";
import * as MdIcons from "react-icons/md";
import * as ImIcons from "react-icons/im";
import * as BiIcons from "react-icons/bi";
import * as IoClipboard from "react-icons/io5";
import { HeaderDropDoenoptions } from "./HeaderDropDoenoptions";
import { FiChevronDown } from "react-icons/fi";
import { PATH } from "config";
import { Button, Menu } from "antd";

import { Dropdown, Space } from "antd";
import { useEffect } from "react";
import { DropdownButton } from "react-bootstrap";
const SubMenu = Menu.SubMenu;
const HRM_PATH = "/app";
export function DropDownListData() {
  const userNavbarData = [
    {
      title: "Dashboard",
      path: `${HRM_PATH}/Dashboard`,
      icon: <AiIcons.AiOutlineDashboard />,
    },
    {
      title: "Employees",
      path: `${HRM_PATH}/people`,
      icon: <ImIcons.ImStatsBars />,
    },
    {
      title: "Attendance",
      path: `${HRM_PATH}/attendance/AttendanceHistory/AttendanceDashboard`,
      icon: <AiIcons.AiFillCheckSquare />,
      submenu: [
        {
          title: "Attendance",
          path: `${HRM_PATH}/attendance/AttendanceHistory/AttendanceDashboard`,
        },
        {
          title: "Daily attendance",
          path: `${HRM_PATH}/attendance/AttendanceHistory/DailyAttendance`,
        },
        {
          title: "Leave Requests",
          path: `${HRM_PATH}/attendance/AttendanceHistory/LeaveRequest`,
        },
        {
          title: "Late comings",
          path: `${HRM_PATH}/attendance/AttendanceHistory/Late-Coming`,
        },
      ],
    },
    {
      title: "Organization settings",
      icon: <RiIcons.RiProjectorFill />,
      submenu: [
        {
          title: "Users",
          path: `${HRM_PATH}/settings-manageUser`,
        },
        {
          title: "Departments",
          path: `${HRM_PATH}/departments`,
        },
        {
          title: "Teams",
          path: `${HRM_PATH}/teams`,
        },
        {
          title: "Events",
          path: `${HRM_PATH}/events`,
        },
        {
          title: "Holidays",
          path: `${HRM_PATH}/attendance/AttendanceHistory/holiday`,
        },
        // {
        //   title: "Suggestions",
        //   path: `${HRM_PATH}/attendance/AttendanceHistory/Late-Coming`,
        // },
      ],
    },

    {
      title: "Assest management",
      icon: <RiIcons.RiProjectorFill />,
      submenu: [
        {
          title: "Assets",
          path: `${HRM_PATH}/assets`,
        },
        {
          title: "Assests allocation",
          path: `${HRM_PATH}/asset-allocation`,
        },
      ],
    },

    {
      title: "Hiring",
      path: `${HRM_PATH}/hiring`,
      icon: <IoClipboard />,
      // submenu: [
      //   {
      //     title: "Daily attendance",
      //     path: `${HRM_PATH}/attendance/AttendanceHistory/DailyAttendance`,
      //   },
      //   {
      //     title: "Leave Requests",
      //     path: `${HRM_PATH}/attendance/AttendanceHistory/LeaveRequest`,
      //   },
      //   {
      //     title: "Late comings",
      //     path: `${HRM_PATH}/attendance/AttendanceHistory/Late-Coming`,
      //   }
      // ]
    },
    // {
    //   title: "Settings",
    //   path: `${HRM_PATH}/settings`,
    //   icon: <AiIcons.AiOutlineUnorderedList />,
    // },
  ];
  const adminNavbarData = [
    {
      title: "Dashboard",
      path: PATH.MAINDASHBOARD.replace(":type", "ceo"),
      icon: <AiIcons.AiOutlineDashboard />,
    },
    {
      title: "Projects",
      path: PATH.MAINDASHBOARD,
      icon: <RiIcons.RiProjectorFill />,
    },
    {
      title: "Workload",
      path: PATH.MAINDASHBOARD,
      icon: <FaIcons.FaBalanceScale />,
    },
    {
      title: "Day Planner",
      path: PATH.MAINDASHBOARD,
      icon: <BsIcons.BsListUl />,
    },
    {
      title: "Video Call",
      path: PATH.VIDEOCALL,
      icon: <BsIcons.BsCameraVideo />,
    },
    {
      title: "Team Scheduler",
      path: PATH.MAINDASHBOARD,
      icon: <GoIcons.GoDash />,
    },
  ];
  const assests = {
    items: [
      {
        id: 1,
        name: "foo",
      },
      {
        id: 2,
        name: "bar",
      },
    ],
  };
  const history = useHistory();
  const location = useLocation();
  // console.log(history.location, "history");
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const items = [
    getItem("Navigation One", "sub1", <FiChevronDown />, [
      getItem(
        "Item 1",
        null,
        null,
        [getItem("Option 1", "1"), getItem("Option 2", "2")],
        "group"
      ),
      getItem(
        "Item 2",
        null,
        null,
        [getItem("Option 3", "3"), getItem("Option 4", "4")],
        "group"
      ),
    ]),
    getItem("Navigation Two", "sub2", <FiChevronDown />, [
      getItem("Option 5", "5"),
      getItem("Option 6", "6"),
      getItem("Submenu", "sub3", null, [
        getItem("Option 7", "7"),
        getItem("Option 8", "8"),
      ]),
    ]),
    getItem("Navigation Three", "sub4", <FiChevronDown />, [
      getItem("Option 9", "9"),
      getItem("Option 10", "10"),
      getItem("Option 11", "11"),
      getItem("Option 12", "12"),
    ]),
  ];
  const onClick = (e) => {
    console.log("click", e);
  };
  useEffect(() => { }, [history]);

  function handleButtonClick(e) { }

  function handleMenuClick(e) { }
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Xeven health</Menu.Item>
      <Menu.Item key="2">Covis</Menu.Item>
    </Menu>
  );
  return (
    <>
      <ul className="menu ">
        {userNavbarData.map((data, index) => {
          return (
            <li
              key={index}
              className="at_mainmenu_link nav-menu position-relative"
            >
              {data?.submenu?.length > 0 ? (
                <a
                  className={`default-link-subbar smooth hover-enabled css-1i9kywd ${data?.submenu?.some(
                    (item) => item.path === history?.location?.pathname
                  ) && "active"
                    }`}
                >
                  {console.log("data?.submenu", data?.submenu[0])}
                  <Dropdown
                    className={`${history?.location?.pathname === data?.submenu[index] &&
                      "bg-dark"
                      }`}
                    overlay={
                      <Menu className="dropdown_navbar">
                        {data.submenu?.map((submenu, index) => {
                          return (
                            <Menu.Item>
                              <Link
                                className={`default-link-subbar smooth hover-enabled css-1i9kywd ${history?.location?.pathname === data.path &&
                                  "active"
                                  }`}
                                to={submenu.path}
                              >
                                {submenu.title}
                              </Link>
                            </Menu.Item>
                          );
                        })}
                      </Menu>
                    }
                  >
                    <span>
                      {" "}
                      {data.title} <FiChevronDown />
                    </span>
                    {/* <span className="default-link-subbar-line" /> */}
                  </Dropdown>
                  <span className="default-link-subbar-line" />
                </a>
              ) : (
                <Link
                  className={`default-link-subbar smooth hover-enabled css-1i9kywd ${history?.location?.pathname === data.path && "active"
                    } ${index + 1 == userNavbarData.length && "border-0"}`}
                  to={data?.path}
                >
                  {data.title}
                  <span className="default-link-subbar-line" />
                </Link>
              )}

              {/* {data?.subNav && <HeaderDropDoenoptions navs={data?.subNav} />} */}
            </li>
          );
        })}
      </ul>
      {/* <div className="d-flex align-item-center ">
        <Dropdown overlay={menu}>
          <span className="dropDown-stying ">Xeven Solution <MdIcons.MdOutlineArrowDropDown className="" size={25} /></span>
        </Dropdown>
      </div> */}
    </>
  );
}
