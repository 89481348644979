import ProfileAvatar from "./profile-avatar.jpg";
import AppLogo from "./VC-Logo.png";
import AppLogo1 from "./VC-Logo1.png";
import attendeceLogo from "./attendece-logo.png";
import LeaveEmail from "./LeaveEmail.png";
import addJob from "./addJob.png";
import location from "./Location-form.png";
import informationIt from "./information-it.png";
import shortAnswer from "./shortAnswer.png";
import LongAnswer from "./LongAnswer.png";
import Checkboxs from "./Checkbox.png";
import yesnoimg from "./YesNo.png";
import MultipleChoice from "./MultipleChoice.png";
import fileuploaderimg from "./File.png";
import AddPrevious from "./AddPrevious.png";
import wordout from "./wordout.png";
import imdeedimg from "./imdeedimg.png";
import glassdoorimg from "./glassdoor.png";
import ziprecruiter from "./ziprecruiter.png";
import users from "./users.png";
import turnOver from "./turnOver.png";
import searchImage from "./searchImage.png";
import fiIeImge from "./fiIeImge.png";
import peopleImage from "./peopleImage.png";
import handsImage from "./handsImage.png";
import giftImage from "./giftImage.png";
import teerImage from "./teerImage.png";
import magnetImage from "./magnetImage.png";
import konImage from "./konImage.png";
import dealClosed from "./dealClosed.png";
import projectManagementIcon from "./ProjectManagementIcon.png";
import hrManagerIcon from "./HRManagerIcon.png";
import resourceTrackerIcon from "./ResourceTrackerIcon.png";
import financialSystemIcon from "./FinancialSystemIcon.png";
import fileCloudIcon from "./FileCloudIcon.png";
import downloadresume from "./downloadresume.png";
import notificatio1 from "./notification1.png";
import notificatio2 from "./notification2.png";
import notificatio3 from "./notification3.png";
import notificatio4 from "./notification4.png";
import notificatio5 from "./notification5.png";
export const IMAGES = {
  NOTIFICATION5: notificatio5,
  NOTIFICATION4: notificatio4,
  NOTIFICATION3: notificatio3,
  NOTIFICATION2: notificatio2,
  NOTIFICATION1: notificatio1,
  DOWNLOADRESUME: downloadresume,
  AVATAR: ProfileAvatar,
  APPLOGO: AppLogo,
  APPLOGO1: AppLogo1,
  ATTENDANCELOGO: attendeceLogo,
  LEAVEEMAIL: LeaveEmail,
  ADDJOB: addJob,
  LOCATIONLOGO: location,
  INFORMATIONIT: informationIt,
  SHORTANSWER: shortAnswer,
  LONGANSWER: LongAnswer,
  CHEKBOXES: Checkboxs,
  YESNOIMG: yesnoimg,
  MULTIPLECHOICE: MultipleChoice,
  FILEUPLOADERIMG: fileuploaderimg,
  ADDPREVIOUS: AddPrevious,
  WORDOUT: wordout,
  INDEENIMG: imdeedimg,
  GLASSDOORIMG: glassdoorimg,
  ZIPCRUITER: ziprecruiter,
  USERS: users,
  FILEIMAGE: fiIeImge,
  TURNOVER: turnOver,
  SEARCHIMAGE: searchImage,
  GIFTIMAGE: giftImage,
  HANDSIMAGE: handsImage,
  PEOPLEIMAGE: peopleImage,
  CONVERTEDLEADS: konImage,
  COLDLEAD: magnetImage,
  TARGET: teerImage,
  DEALCLOSED: dealClosed,

  RESOURCE_TRACKER_ICON: resourceTrackerIcon,
  HRMANAGER_ICON: hrManagerIcon,
  FINANCIAL_SYSTEM_ICON: financialSystemIcon,
  PROJECT_MANAGEMENT_ICON: projectManagementIcon,
  FILE_CLOUD_ICON: fileCloudIcon,
};
