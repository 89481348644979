import { TEAM_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
    getTeamListLoading: false,
    getTeamListSuccess: false,
    getTeamListFailure: false,
    getTeamListError: null,
    teamList: [],

    blockUnblockTeamLoading: false,
    blockUnblockTeamSuccess: false,
    blockUnblockTeamFailure: false,
    blockUnblockTeamError: null,

    addNewTeamLoading: false,
    addNewTeamSuccess: false,
    addNewTeamFailure: false,
    addNewTeamError: null,
    singleTeamData: null,

    singleTeamDetailLoading: false,
    singleTeamDetailSuccess: false,
    singleTeamDetailFailure: false,
    singleTeamDetailError: null,

    addTeamMembersLoading: false,
    addTeamMembersSuccess: false,
    addTeamMembersFailure: false,
    addTeamMembersError: null,

    updateTeamDetailLoading: false,
    updateTeamDetailSuccess: false,
    updateTeamDetailFailure: false,
    updateTeamDetailError: null,

    deleteTeamMemberLoading: false,
    deleteTeamMemberSuccess: false,
    deleteTeamMemberFailure: false,
    deleteTeamMemberError: null,
};

export const teamReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TEAM_REQUEST.GET_TEAM_LIST_REQUEST:
            return {
                ...state,
                getTeamListLoading: true,
                getTeamListSuccess: false,
                getTeamListFailure: false,
                getTeamListError: null,
            }
        case TEAM_REQUEST.GET_TEAM_LIST_FAILURE:
            return {
                ...state,
                getTeamListLoading: false,
                getTeamListSuccess: false,
                getTeamListFailure: true,
                getTeamListError: action.payload
            }
        case TEAM_REQUEST.GET_TEAM_LIST_SUCCESS:
            return {
                ...state,
                getTeamListLoading: false,
                getTeamListSuccess: true,
                getTeamListFailure: false,
                getTeamListError: null,
                teamList: action.payload
            }
        case TEAM_REQUEST.BLOCK_UNBLOCK_TEAM_REQUEST:
            return {
                ...state,
                blockUnblockTeamLoading: true,
                blockUnblockTeamSuccess: false,
                blockUnblockTeamFailure: false,
                blockUnblockTeamError: null,
            }
        case TEAM_REQUEST.BLOCK_UNBLOCK_TEAM_FAILURE:
            return {
                ...state,
                blockUnblockTeamLoading: false,
                blockUnblockTeamSuccess: false,
                blockUnblockTeamFailure: true,
                blockUnblockTeamError: action.payload
            }
        case TEAM_REQUEST.BLOCK_UNBLOCK_TEAM_SUCCESS:
            let teamDeleteList = state.teamList
            for (let i = 0; i < teamDeleteList.length; i++) {
                if (action.payload === teamDeleteList[i].projectTeamId) {
                    teamDeleteList.splice(i, 1);
                }
            }
            return {
                ...state,
                blockUnblockTeamLoading: false,
                blockUnblockTeamSuccess: true,
                blockUnblockTeamFailure: false,
                blockUnblockTeamError: null,
                teamList: teamDeleteList
            }
        case TEAM_REQUEST.ADD_NEW_TEAM_REQUEST:
            return {
                ...state,
                addNewTeamLoading: true,
                addNewTeamSuccess: false,
                addNewTeamFailure: false,
                addNewTeamError: null,
            }
        case TEAM_REQUEST.ADD_NEW_TEAM_FAILURE:
            return {
                ...state,
                addNewTeamLoading: false,
                addNewTeamSuccess: false,
                addNewTeamFailure: true,
                addNewTeamError: action.payload
            }
        case TEAM_REQUEST.ADD_NEW_TEAM_SUCCESS:
            return {
                ...state,
                addNewTeamLoading: false,
                addNewTeamSuccess: true,
                addNewTeamFailure: false,
                addNewTeamError: null,
                singleTeamData: action.payload
            }
        case TEAM_REQUEST.GET_SINGLE_TEAM_DETAIL_REQUEST:
            return {
                ...state,
                singleTeamDetailLoading: true,
                singleTeamDetailSuccess: false,
                singleTeamDetailFailure: false,
                singleTeamDetailError: null,
            }
        case TEAM_REQUEST.GET_SINGLE_TEAM_DETAIL_FAILURE:
            return {
                ...state,
                singleTeamDetailLoading: false,
                singleTeamDetailSuccess: false,
                singleTeamDetailFailure: true,
                singleTeamDetailError: action.payload
            }
        case TEAM_REQUEST.GET_SINGLE_TEAM_DETAIL_SUCCESS:
            return {
                ...state,
                singleTeamDetailLoading: false,
                singleTeamDetailSuccess: true,
                singleTeamDetailFailure: false,
                singleTeamDetailError: null,
                singleTeamData: action.payload
            }
        case TEAM_REQUEST.RESET_SINGLE_TEAM_DETAIL_REQUEST:
            return {
                ...state,
                singleTeamDetailLoading: false,
                singleTeamDetailSuccess: false,
                singleTeamDetailFailure: false,
                singleTeamDetailError: null,
                singleTeamData: null
            }
        case TEAM_REQUEST.ADD_TEAM_MEMBERS_REQUEST:
            return {
                ...state,
                addTeamMembersLoading: true,
                addTeamMembersSuccess: false,
                addTeamMembersFailure: false,
                addTeamMembersError: null,
            }
        case TEAM_REQUEST.ADD_TEAM_MEMBERS_FAILURE:
            return {
                ...state,
                addTeamMembersLoading: false,
                addTeamMembersSuccess: false,
                addTeamMembersFailure: true,
                addTeamMembersError: action.payload
            }
        case TEAM_REQUEST.ADD_TEAM_MEMBERS_SUCCESS:
            let singleTeamUpdatedData = state.singleTeamData
            for (let i = 0; i < action.payload.length; i++) {
                singleTeamUpdatedData.userInProjectTeams.push(action.payload[i])
            }
            return {
                ...state,
                addTeamMembersLoading: false,
                addTeamMembersSuccess: true,
                addTeamMembersFailure: false,
                addTeamMembersError: null,
                singleTeamData: singleTeamUpdatedData
            }
        case TEAM_REQUEST.UPDATE_TEAM_DETAIL_REQUEST:
            return {
                ...state,
                updateTeamDetailLoading: true,
                updateTeamDetailSuccess: false,
                updateTeamDetailFailure: false,
                updateTeamDetailError: null,
            }
        case TEAM_REQUEST.UPDATE_TEAM_DETAIL_FAILURE:
            return {
                ...state,
                updateTeamDetailLoading: false,
                updateTeamDetailSuccess: false,
                updateTeamDetailFailure: true,
                updateTeamDetailError: action.payload
            }
        case TEAM_REQUEST.UPDATE_TEAM_DETAIL_SUCCESS:
            let singleTeamDetail = state.singleTeamData
            singleTeamDetail.title = action.payload.title
            singleTeamDetail.teamLeadId = action.payload.teamLeadId
            return {
                ...state,
                updateTeamDetailLoading: false,
                updateTeamDetailSuccess: true,
                updateTeamDetailFailure: false,
                updateTeamDetailError: null,
                singleTeamData: singleTeamDetail
            }

        case TEAM_REQUEST.DELETE_TEAM_MEMBER_REQUEST:
            return {
                ...state,
                deleteTeamMemberLoading: true,
                deleteTeamMemberSuccess: false,
                deleteTeamMemberFailure: false,
                deleteTeamMemberError: null,
            }
        case TEAM_REQUEST.DELETE_TEAM_MEMBER_FAILURE:
            return {
                ...state,
                deleteTeamMemberLoading: false,
                deleteTeamMemberSuccess: false,
                deleteTeamMemberFailure: true,
                deleteTeamMemberError: action.payload
            }
        case TEAM_REQUEST.DELETE_TEAM_MEMBER_SUCCESS:
            let singleTeamForDelete = state.singleTeamData
            for (let i = 0; i < singleTeamForDelete.userInProjectTeams.length ; i++) {
                if (singleTeamForDelete.userInProjectTeams[i].userId === action.payload) {
                    singleTeamForDelete.userInProjectTeams.splice(i, 1);
                }
            }
            return {
                ...state,
                deleteTeamMemberLoading: false,
                deleteTeamMemberSuccess: true,
                deleteTeamMemberFailure: false,
                deleteTeamMemberError: null,
                singleTeamData: singleTeamForDelete
            }
        default:
            return state;
    }
};