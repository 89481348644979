const Initial_State = {
    event: [{ title: "Jaffer's Birthday", date: '2021-12-11' }]
};

function EventsReducer(state = Initial_State, action) {
    switch (action.type) {
        case "Birthdays":
            var tempArray = state.event;
            console.log(tempArray, "temparray")
            tempArray.push({ title: "Jaffer's Birthday", date: '2021-12-11' });


            return {
                ...state,
                event: tempArray
            };
        case "Holidays":
            var tempArray_ = state.event;
            console.log(tempArray_, "temparray")
            tempArray_.push({ title: "Sports day", date: '2021-12-02' });
            return {
                ...state,
                event: tempArray_
            };

        default:
            return state;
    }
}
export default EventsReducer;
