import { APP_PREFIX_PATH } from "configs/AppConfig";

const ERROR = {
  SYSTEM_ERROR: "System error. Please try again later!",
};
const PATH = {
  MAINDASHBOARD: APP_PREFIX_PATH + "/Dashboard",
  RECRUITMENTDASHBOARD: APP_PREFIX_PATH + "/recruitment/Dashboard",

  // BUISNESSDEVELOPMENTDASHBOARD: APP_PREFIX_PATH + "/buisnes-development/Dashboard",
};
const MANAGER_ROLE = [
  { id: 15, title: "Admin" },
  { id: 14, title: "Manager" },
  { id: 2, title: "Employee" },
];
const Gender_Lookps = [
  {
    Id: 1,
    Title: "Male",
    Type: "Gender",
  },
  {
    Id: 2,
    Title: "Female",
    Type: "Gender",
  },
];

const Marital_Status_Lookps = [
  {
    Id: 101,
    Title: "Single",
    Type: "MaritalStatus",
  },
  {
    Id: 102,
    Title: "Married",
    Type: "MaritalStatus",
  },
  {
    Id: 103,
    Title: "Divorced",
    Type: "MaritalStatus",
  },
];

const Pay_Schedule_Lookps = [
  {
    Id: 201,
    Title: "Ever other week",
    Type: "PaySchedule",
  },
  {
    Id: 202,
    Title: "Twice a month",
    Type: "PaySchedule",
  },
];

const Pay_Type_Lookps = [
  {
    Id: 301,
    Title: "Salary",
    Type: "PayType",
  },
  {
    Id: 302,
    Title: "Hourly",
    Type: "PayType",
  },
  {
    Id: 303,
    Title: "Commission only",
    Type: "PayType",
  },
];

const Ethnicity_Lookps = [
  {
    Id: 401,
    Title: "Asian",
    Type: "Ethnicity",
  },
  {
    Id: 402,
    Title: "Black",
    Type: "Ethnicity",
  },
  {
    Id: 403,
    Title: "White",
    Type: "Ethnicity",
  },
];

const Job_Title_Lookps = [
  {
    Id: 501,
    Title: "Software Developer",
    Type: "JobTitle",
  },
  {
    Id: 502,
    Title: "Project Manager",
    Type: "JobTitle",
  },
  {
    Id: 503,
    Title: "Accountant",
    Type: "JobTitle",
  },
];

const Division_Lookps = [
  {
    Id: 601,
    Title: "Asia",
    Type: "Division",
  },
  {
    Id: 602,
    Title: "Europe",
    Type: "Division",
  },
  {
    Id: 603,
    Title: "USA",
    Type: "Division",
  },
];

const Pay_Rate_Period_Lookps = [
  {
    Id: 701,
    Title: "Day",
    Type: "PayRatePeriod",
  },
  {
    Id: 702,
    Title: "Week",
    Type: "PayRatePeriod",
  },
  {
    Id: 703,
    Title: "Month",
    Type: "PayRatePeriod",
  },
  {
    Id: 704,
    Title: "Quarter",
    Type: "PayRatePeriod",
  },
  {
    Id: 705,
    Title: "Year",
    Type: "PayRatePeriod",
  },
  {
    Id: 706,
    Title: "Pay Period",
    Type: "PayRatePeriod",
  },
  {
    Id: 707,
    Title: "Piece",
    Type: "PayRatePeriod",
  },
];
const baseUrl = "https://192.168.10.71:45455/api/";
// const baseUrl = "https://littlebrassdog61.conveyor.cloud/";
const APP_SETTINGS = {
  API_PATH: {
    DEPARTMENT: {
      getDepartmentList: baseUrl + "Department/GetDepartmentList",
      addNewDepartment: baseUrl + "Department/AddDepartment",
      updateDepartment: baseUrl + "Department/UpdateDepartment",
      deleteDepartment: baseUrl + "Department/DeleteDepartment",
      getDepartmentSelectList: baseUrl + "Department/GetDepartmentSelectList",
    },
    EMPLOYEE: {
      getEmployeeSelectList: baseUrl + "Employee/GetEmployeeShortList",
      getAllEmployeeList: baseUrl + "Employee/GetEmployees",
      addNewEmployee: baseUrl + "Employee/AddEmployee",
      deleteEmployee: baseUrl + "Employee/BlockUnblockEmployee",
      getSingleEmployee: baseUrl + "Employee/GetEmployee",
      updateEmployee: baseUrl + "Employee/UpdateEmployee",
    },
    LOCATION: {
      getLocationSelectList: baseUrl + "Location/GetLocationSelectList",
    },
    COMMON: {
      getCountryList: baseUrl + "Common/GetCountries",
      getStateList: baseUrl + "Common/GetStatesByCountryId",
      getCityList: baseUrl + "Common/GetCitiesByStateId",
      getUserSelectList: baseUrl + "Common/GetUserSelectList",
    },
    TEAM: {
      getTeamList: baseUrl + "Team/GetTeamsList",
      blockUnblockTeam: baseUrl + "Team/BlockUnblockTeam",
      addNewTeam: baseUrl + "Team/AddTeamDetail",
      getSingleTeamDetail: baseUrl + "Team/GetSingleTeam",
      addTeamMember: baseUrl + "Team/AddTeamMembers",
      updateTeamDetail: baseUrl + "Team/UpdateTeamDetail",
      deleteTeamMember: baseUrl + "Team/RemoveTeamMember",
    },
  },
};
export {
  ERROR,
  PATH,
  APP_SETTINGS,
  Gender_Lookps,
  Marital_Status_Lookps,
  Pay_Schedule_Lookps,
  Pay_Type_Lookps,
  Ethnicity_Lookps,
  Job_Title_Lookps,
  Division_Lookps,
  Pay_Rate_Period_Lookps,
  MANAGER_ROLE,
};
